export default class Recognizer {
	init(jbob, container) {
		$('.recognizer', container).each((index, form) => {
			form = $(form);

			form.find('textarea').on('input', (e) => {
				let area = $(e.currentTarget);
				let length = area.val().length;
				form.find('.char-counter').text(length);
			});

			form.submit((e) => {
				e.preventDefault();
				jbob.submitButton(form).prop('disabled', true);

				let key = $('input[name=key]').val();

				let result = $('.results');
				let spinner = $('<div class="spinner-border m-5" role="status"><span class="visually-hidden">Loading...</span></div>');
				result.removeClass('text-bg-danger').addClass('text-bg-info').find('.card-body').empty().append(spinner);

				$.ajax({
					url: form.attr('action'),
					method: 'GET',
					data: form.serialize(),
					headers: {
						'X-SB-Key': key,
					},
					dataType: 'JSON',
					success: function(data) {
						let cell = result.removeClass('text-bg-info').find('.card-body');
						let table = $('<table class="table"></table>');
						let tbody = $('<tbody></tbody>');
						table.append(tbody);
						cell.empty().append(table);

						data.forEach((d) => {
							let row = $('<tr><td>' + d.entity + '</td><td>' + d.wikidata + '</td></tr>');
							table.append(row);
						});

						jbob.submitButton(form).prop('disabled', false);
					},
					error: function(jqXHR) {
						let response = JSON.parse(jqXHR.responseText);
						let error_msg = response.message;
						if (!error_msg) {
							error_msg = response.error;
						}

						result.removeClass('text-bg-info').addClass('text-bg-danger').find('.card-body').empty();
						let row = $('<div class="row"><div class="col">' + error_msg + '</div></div>');
						result.append(row);
						jbob.submitButton(form).prop('disabled', false);
					}
				});
			});
		});
	}
}
