import './vendor/hs.chartjs.js';
import 'chartjs-adapter-date-fns';

if ($('#stats').length != 0) {
	let types = ['queries', 'credit', 'time', 'storage'];
	window.hs_config = {
		themeAppearance: {
			layoutSkin: 'default',
		}
	}

	types.forEach((type) => {
		HSCore.components.HSChartJS.init(document.querySelector('#chart-' + type), {
			type: "line",
			data: {
				datasets: [{
					data: [],
					backgroundColor: ["rgba(55, 125, 255, .5)", "rgba(255, 255, 255, .2)"],
					borderColor: "#377dff",
					borderWidth: 2,
					pointRadius: 0,
					hoverBorderColor: "#377dff",
					pointBackgroundColor: "#377dff",
					pointBorderColor: "#fff",
					pointHoverRadius: 0,
					tension: 0.4
				}],
			},
			options: {
				gradientPosition: {"y1": 200},
				scales: {
					y: {
						min: 0,
						grid: {
							color: "#e7eaf3",
							drawBorder: false,
							zeroLineColor: "#e7eaf3"
						},
						ticks: {
							min: 0,
							max: 100,
							stepSize: 20,
							fontColor: "#97a4af",
							fontFamily: "Open Sans, sans-serif",
							padding: 10,
						}
					},
					x: {
						type: "time",
						time: {
							unit: 'day',
							tooltipFormat: 'MMM d'
						},
						min: (new Date()).setDate((new Date).getDate() - 30),
						max: new Date(),
						grid: {
							display: false,
							drawBorder: false
						},
						ticks: {
							fontSize: 12,
							fontColor: "#97a4af",
							fontFamily: "Open Sans, sans-serif",
							padding: 5
						}
					}
				},
				hover: {
					mode: "nearest",
					intersect: true
				}
			}
		});
	});

	$.ajax({
		method: 'GET',
		url: '/in/stats/fetch',
		dataType: 'JSON',
		success: function(data) {
			$('.storage_graph_scale').text(data.storage_scale);

			types.forEach((type) => {
				const updatingLineChart = HSCore.components.HSChartJS.getItem('chart-' + type);
				updatingLineChart.data.datasets[0].data = data[type];
				updatingLineChart.update();
			});
		}
	});
}

$('.spark').each(function() {
	window.hs_config = {
		themeAppearance: {
			layoutSkin: 'default',
		}
	};

	HSCore.components.HSChartJS.init(document.querySelector('#' + $(this).attr('id')), {
		type: "line",
		data: {
			datasets: [{
				data: [],
				backgroundColor: ["rgba(55, 125, 255, .5)", "rgba(255, 255, 255, .2)"],
				borderColor: "#377dff",
				borderWidth: 2,
				pointRadius: 0,
				hoverBorderColor: "#377dff",
				pointBackgroundColor: "#377dff",
				pointBorderColor: "#fff",
				pointHoverRadius: 0,
				tension: 0.4
			}],
		},
		options: {
			gradientPosition: {"y1": 200},
			scales: {
				y: {
					min: 0,
					display: false,
				},
				x: {
					type: "time",
					time: {
						unit: 'day',
						tooltipFormat: 'MMM d'
					},
					min: (new Date()).setDate((new Date).getDate() - 30),
					max: new Date(),
					display: false,
				}
			},
			plugins: {
				tooltip: false,
			}
		}
	});

	$.ajax({
		method: 'GET',
		url: $(this).attr('data-stats'),
		dataType: 'JSON',
		success: function(data) {
			const updatingLineChart = HSCore.components.HSChartJS.getItem(data.id);
			updatingLineChart.data.datasets[0].data = data.views;
			updatingLineChart.update();
		}
	});
});
