import select2 from 'select2';
select2();

export default class Extractor {
	init(jbob, container) {
		this.api_endpoint = 'https://www.wikidata.org/w/api.php';

		let editor = container.find('.extract-edit');
		if (editor.length) {
			$('.language-autocomplete', container).each((e, select) => {
				select = $(select);

				$.ajax({
					url: this.api_endpoint + '?action=query&meta=siteinfo&format=json&origin=*&siprop=languages',
					method: 'GET',
					dataType: 'JSON',
					success: (data) => {
						select.select2({
							theme: "bootstrap-5",
							data: data.query.languages.map((d) => {
								return {
									id: d.code,
									text: d['*'],
								};
							}),
						});
					},
				});
			});

			editor.on('submit', 'form', function(e) {
				e.preventDefault();
				let form = $(this);

				jbob.submitButton(form).prop('disabled', true);

				let data = {
					including: [],
					langs: [],
					filters: [],
				};

				editor.find('tbody .prop-row').each((e, sel) => {
					sel = $(sel);
                    let prop_id = sel.find('select[name=prop] option:selected');

                    if (prop_id.length) {
    					let prop = {
                            prop: prop_id.val(),
    						opt: sel.find('[name=optional]').prop('checked'),
    					};

                        data.including.push(prop);
                    }
				});

				editor.find('tbody select[name=langs] option:selected').each((e, sel) => {
					data.langs.push($(sel).val());
				});

				editor.find('tbody .property-filter').each((e, row) => {
					row = $(row);

					let prop = row.find('select[name=filter] option:selected');
					if (prop.length) {
						let filt = {
							prop: prop.val(),
							dt: row.find('input[name=datatype]').val(),
							op: row.find('select[name=compare] option:selected').val(),
							value: [],
						};

						row.find('.filter-value input').each((e, val) => {
							filt.value.push($(val).val());
						});

						row.find('.filter-value select option:selected').each((e, val) => {
							filt.value.push($(val).val());
						});

						data.filters.push(filt);
					}
				});

				$.ajax({
					method: form.attr('method'),
					url: form.attr('action'),
					dataType: 'JSON',
					data: {
						title: form.find('input[name=title]').val(),
						slug: form.find('input[name=slug]').val(),
						_token: form.find('input[name=_token]').val(),
						query: JSON.stringify(data),
					},
					success: function(response) {
						if (response.status == 'ok') {
							form.find('input[name=slug]').val(response.slug);
							jbob.fetchRemoteModal(response.preview_url);
						}

						jbob.submitButton(form).prop('disabled', false);
					},
					error: function() {
						jbob.submitButton(form).prop('disabled', false);
					}
				});
			});
		}

		$('.property-autocomplete', container).each((index, select) => {
			select = $(select);

			if (select.closest('tfoot').length != 0) {
				return;
			}

			select.select2({
				theme: 'bootstrap-5',
				placeholder: select.attr('placeholder'),
				dropdownParent: select.closest('.modal'),
				ajax: {
					url: (params) => {
						return this.api_endpoint + '?action=wbsearchentities&language=en&uselang=en&type=property&format=json&formatversion=2&errorformat=plaintext&origin=*&limit=12&search=' + params.term;
					},
					dataType: 'JSON',
					processResults: function (data) {
						return {
							results: data.search.map(function(d) {
								return {
									id: d.id,
									text: d.label,
									html: '<p class="lead">' + d.label + '</p><p>' + d.description + '</p>',
									datatype: d.datatype,
								}
							})
						};
					},
				},
				escapeMarkup: function(markup) {
					return markup;
				},
				templateResult: function(data) {
					return data.html;
				},
				templateSelection: function(data) {
					return data.text;
				},
			});

			select.on('select2:select', (e) => {
				let dt = e.params.data.datatype;
				let row = select.closest('tr');

				row.find('input:hidden[name=datatype]').val(dt);

				if (select.closest('.property-filter').length != 0) {
					let operator = $('.filter-operators .' + dt).first();
					if (operator.length == 0) {
						dt = 'unknown';
						operator = $('.filter-operators .unknown').first();
					}

					let s = operator.clone();
					row.find('.filter-operator').empty().append(s);

					s.change((e) => {
						let select = $(e.currentTarget);
						let selected = select.find('option:selected').val();
						let row = select.closest('tr');
						let datatype = row.find('input:hidden[name=datatype]').val();
						let cell = $('.filter-cells .' + datatype).children().first().clone();
						let value_slot = row.find('.filter-value');

						switch(selected) {
							case 'equal':
							case 'differ':
							case 'less':
							case 'more':
							case 'regex':
								value_slot.empty().append(cell);
								break;
							case 'between':
							case 'notbetween':
								let dup = cell.clone();
								value_slot.empty().append(cell).append(dup);
								this.initCell(dup);
								break;
							case 'any':
							case 'none':
								value_slot.empty();
								break;
						}

						this.initCell(cell);
					});

					s.change();
				}
			});
		});

		let preview = container.find('.extract-preview');
		if (preview.length) {
			let table = preview.find('.preview-table');
			let url = table.attr('data-url');

			$.ajax({
				method: 'GET',
				url: url,
				dataType: 'HTML',
				success: function(data) {
					table.empty().append(data);
				}
			});
		}
	}

	initCell(cell) {
		if (cell.hasClass('entity-autocomplete')) {
			cell.select2({
				theme: 'bootstrap-5',
				dropdownParent: cell.closest('.modal'),
				ajax: {
					url: (params) => {
						return this.api_endpoint + '?action=wbsearchentities&language=en&uselang=en&type=item&format=json&formatversion=2&errorformat=plaintext&origin=*&limit=12&search=' + params.term;
					},
					dataType: 'JSON',
					processResults: function (data) {
						return {
							results: data.search.map(function(d) {
								return {
									id: d.id,
									text: d.label,
									html: '<p class="lead">' + d.label + '</p><p>' + d.description + '</p>',
									datatype: d.datatype,
								}
							})
						};
					},
				},
				escapeMarkup: function(markup) {
					return markup;
				},
				templateResult: function(data) {
					return data.html;
				},
				templateSelection: function(data) {
					return data.text;
				},
			});
		}
	}
}
