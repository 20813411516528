import './bootstrap';

import Commons from '../commons/commons.js';

import './vendor/hs.core.js';

import './vendor/hs.datatables.js';
HSCore.components.HSDatatables.init('.js-datatable');

import './vendor/hs.clipboard.js';
HSCore.components.HSClipboard.init('.js-clipboard');

import './vendor/hs.tom-select.js';
HSCore.components.HSTomSelect.init('.js-select');

import HSSideNav from './vendor/hs-navbar-vertical-aside/src/hs-navbar-vertical-aside.js';
import HSScrollspy from './vendor/hs-scrollspy/src/js/hs-scrollspy.js';
import HSTogglePassword from './vendor/hs-toggle-password/src/js/hs-toggle-password.js';

import jBob from 'jbob';
import spfmt from 'sparql-formatter';

import './graphs';
import Extractor from './extractor';
import Recognizer from './recognition';

import.meta.glob([
	'../../images/**',
]);

(function($) {
	var j = new jBob();
	j.init({
		initFunction: function(container) {
			let e = new Extractor();
			e.init(j, container);

			let r = new Recognizer();
			r.init(j, container);

			$('a.post-link', container).click((e) => {
				e.preventDefault();
				$.ajax({
					method: 'POST',
					url: $(e.currentTarget).attr('href'),
					dataType: 'JSON',
					data: {
						_token: $('meta[name=csrf-token]').attr('content'),
					},
					success: function(data) {
						window.location.href = data.redirect;
					}
				});
			});

			$('.reveal-configs', container).each((index, item) => {
				let it = $(item);
				let target = it.attr('data-reveal');

				it.find('input:radio').change((e) => {
					let button = $(e.currentTarget);
					if (button.prop('checked')) {
						$(target).toggle(button.val() == 'enabled');
					}
				});
			});

			$('.sparql-format', container).each((index, formattable) => {
				let formatted = $(formattable);
				formatted.text(spfmt(formatted.text()));
			});

			$('form', container).on('submit', (e) => {
				let form = $(e.currentTarget);
				j.submitButton(form).prop('disabled', true);
			});
		},
		fixBootstrap: ['Tooltip', 'Toast'],
	});

	new HSSideNav('.js-navbar-vertical-aside').init();
	new HSTogglePassword('.js-toggle-password');

	$('[data-bs-toggle="tooltip"]').each(function() {
		$(this).tooltip();
	});

	$('.toast').each(function() {
		$(this).toast('show');
	});

	if ($('#navbarSettings').length != 0) {
		new bootstrap.ScrollSpy(document.body, {
			target: '#navbarSettings',
			offset: 100
		});

		new HSScrollspy('#navbarVerticalNavMenu', {
			breakpoint: 'lg',
			scrollOffset: -20
		});
	}

	Commons.initCommons($('body'));
})(jQuery);
